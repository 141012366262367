<template>
    <div class="main_div_admin_dashbord">
        <div class="topnav" id="myTopnav" ref="myTopnav">
            <!-- <a href="" class=""></a> -->
            <!-- <a href="">{{
                    $t("memberLogged.memberManagement")
            }}</a>
            <a href="">{{
                    $t("memberLogged.statisticalManagement")
            }}</a> -->
            <a class="log_out_admin_dashbord" @click="logoutMember">{{
                    $t("memberLogged.logout")
            }}</a>
            <!-- <a href="javascript:void(0);" class="icon">
                <i class="v-icon notranslate mdi mdi-menu theme--light"></i>
            </a> -->
        </div>
        <div class="main_admin_dashbord_new">
            <div class="main_admin_dashbord_new_first">
                <ul>
                    <li class="mebmber_li_item"><a href="">{{ $t("memberLogged.title") }}</a></li>
                </ul>
            </div>
            <div class="main_admin_dashbord_new_second">
                <!-- <h1>{{ $t("memberLogged.bodyMessage") }}</h1> -->
                <h1>Login Success</h1>
                <ins><a @click="goToMISite"> Go to MI site test sample</a></ins> (target url : https://was.logintoaskgileadmedical.co.kr/api/users/testAfterLogin)
                <br> 
                check user_token("token") and redirect_url("url")
                <br>
                <br>
                <ins><a @click="goToMISite3"> Go to MI site</a></ins> (staging target url : https://korea.askgileadmedical.envivent.com/ )
                <p>{{count}}초 후 화면 전환됩니다.</p>
                <!-- <form action="https://was.logintoaskgileadmedical.co.kr/api/users/testAfterLogin" target="_self" method="post" ref="form"> -->
                <!-- <form action="http://localhost:6501/api/users/testAfterLogin" target="_self" method="post" ref="form"> -->
                    <!-- <input type="hidden" name="token" v-model="token">
                    <input type="hidden" name="url" value="https://www.logintoaskgileadmedical.co.kr">
                </form> -->
                <!-- <ins><a @click="goToMISite2"> Go to MI site sample (POST) / Check DevTools - Payload</a></ins> -->
            </div>
            <div>
            </div>
        </div>
    </div>
</template>
<script>
// import '@/assets/css/bootstrap.min.css'
import '@/assets/css/style.css'
import '@/assets/jquery/jquery.min.js'
// import '@/assets/js/bootstrap.bundle.js'
import '@/assets/google-font/stylesheet.css'
import MemberDataService from "@/services/MemberDataService";
import jwt from "jsonwebtoken";
export default {
    data() {
        return {
            token: this.getJwtToken(),
            count: 3,
            timer: null,
        }
    },
    methods: {
        memberLoggedIn() {
            if (this.$refs.myTopnav.className === "topnav") {
                this.$refs.myTopnav.className += " responsive";
            } else {
                this.$refs.myTopnav.className = "topnav";
            }
            MemberDataService.getAll()
                .then((response) => {
                    console.log(response.data)
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        console.log("Unauthorized")
                        localStorage.clear();
                        this.$router.push("login")
                    }
                    else {
                        console.log("Error is : " + e)
                    }
                });
        },
        logoutMember() {
            MemberDataService.logoutAdmin()
                .then((response) => {
                    localStorage.clear();
                    this.$router.push("login")
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        console.log("Unauthorized")
                        localStorage.clear();
                        this.$router.push("login")
                    }
                    else {
                        console.log("Error is : " + e)
                    }
                });
        },
        getJwtToken() {
            const userID_email = localStorage.getItem("memberInfo")

            // 아래 샘플 작동 참고용 링크와 동일함.
            // https://jwt.io/#debugger-io?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJIQ1AiOiJZRVMiLCJVc2VySUQiOiJzYW1wbGVVc2VySUQifQ.zYNDMJCaUSx03GB4L6ntcAUxYfeE5UK3di_spplHsdA
            // jwt key
            // const SECRET_KEY = 'glieadSecuretyKeySample'; // 테스트용
            const SECRET_KEY = 'Hello2023!@#mi'; // 2023.02.10
            // 넘겨줘야 할 데이터
            const payload = {
                HCP: 'YES',
                UserID: userID_email // 'sampleuser@email.com' // 테스트용
            };
            const jwtToken = jwt.sign(payload, SECRET_KEY, {
                algorithm: 'HS256'
            });

            return jwtToken; 
        },
        goToMISite(){
            // const userID_email = localStorage.getItem("memberInfo")

            const targetUrl = 'https://was.askgileadmedicalregistration.co.kr/'; 
            // const targetUrl = 'http://localhost:6501/'; // 
            const addedUrl1 = 'api/users/testAfterLogin'; 
            const addedUrl2 = '?url=https://www.askgileadmedicalregistration.co.kr/login&token='; 

            // // 아래 샘플 작동 참고용 링크와 동일함.
            // // https://jwt.io/#debugger-io?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJIQ1AiOiJZRVMiLCJVc2VySUQiOiJzYW1wbGVVc2VySUQifQ.zYNDMJCaUSx03GB4L6ntcAUxYfeE5UK3di_spplHsdA
            // // jwt key
            // const SECRET_KEY = 'glieadSecuretyKeySample'; // 테스트용
            // // 넘겨줘야 할 데이터
            // const payload = {
            //     HCP: 'YES',
            //     UserID: userID_email // 'sampleuser@email.com' // 테스트용
            // };
            // const jwtToken = jwt.sign(payload, SECRET_KEY, {
            //     algorithm: 'HS256'
            // });

            window.location.href = targetUrl+addedUrl1+addedUrl2+this.getJwtToken();//jwtToken;  
        },
        
        goToMISite3(){
            // const targetUrl = 'https://korea.askgileadmedical.envivent.com/'; // 테스트계
            const targetUrl = 'https://www.askgileadmedical.co.kr/'; // 운영계
            const addedUrl1 = '';//'therapeutic-area/'; 
            const addedUrl2 = '?url=https://www.askgileadmedicalregistration.co.kr/login&token='; 

            window.location.href = targetUrl+addedUrl1+addedUrl2+this.getJwtToken();//jwtToken;  
        },
        
        goToMISite2(){
            this.$refs.form.submit();
        },
        checkLoginStatusAndGoToMISite(){
            // loginCheck
            const memberInfo = localStorage.getItem("memberInfo")
                if(memberInfo){
                    this.timer = setInterval(() => {
                        this.count--
                        if(this.count == 0) clearInterval(this.timer)
                    }, 1000);
                    setTimeout(() => {
                    this.goToMISite3();
                }, 3000);
            }
        }
        // fetchData: function() {
        //     // your fetch logic here
        // }
    }, // methods:


    mounted() {
        // this.goToMISite();

        // this.memberLoggedIn();
        // this.message = "";
        // this.getMember(this.$route.params.id);
        this.checkLoginStatusAndGoToMISite()
    },
    beforeMount() { 
    },
    // created: function() {
    //     this.fetchData();
    // },
}
</script>

<style lang="scss" scoped>
.main_admin_dashbord_new {
    height: 95vh !important;
    width: 100%;
    float: left;
}

body {
    margin: 0;
}

.topnav {
    background-color: #D9D9D9;
    overflow: hidden;
    height: 48px;
}

.topnav a {
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.topnav .icon {
    display: none;
}

.log_out_admin_dashbord {
    float: right !important;
}

@media screen and (max-width: 768px) {
    .topnav a {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .mebmber_li_item {
        margin-top: 0px !important;
    }

    .log_out_admin_dashbord {
        float: left !important;
    }

    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }

    .topnav {
        height: auto !important;
    }

    .main_admin_dashbord_new_first {
        width: 100% !important;
        height: auto !important;
    }

    .main_admin_dashbord_new_second {
        width: 100% !important;
        height: auto !important;

    }
}

.main_admin_dashbord_new_first {
    width: 17%;
    height: 95vh;
    float: left;
    background-color: #F3F3F3;
}

.main_admin_dashbord_new_second {
    width: 83%;
    height: 95vh;
    float: left;
}

.mebmber_li_item {
    list-style-type: none;
    font-weight: 600;
    margin-top: 40px;
}

a {
    text-decoration: none;
    color: black;
}
</style>